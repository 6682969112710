import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Join your Community!", "Start a Club.", "Host a Class.", "Share your Passion.", "Grow your Business.", "Make new Friends.", "Wellness Focused.", "Truly Social Media."];
  const period = 1000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner snapScrolling" id="home">
      <Container>
        <Row className="aligh-items-center p-0">
          <Col xs={12} md={6} xl={7} className="p-0">
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>Your Wellness Network.</h1>
                <h3>Photo, Video, Live-Streaming, Clubs and Classes. </h3>
                <p>Made in Canada with Love.</p>
                <h2><span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Rotatoes", "Returning", "Riggidly" ]'><span className="wrap">{text}</span></span></h2>
                {/* <a href="#connect"><button>Connect<ArrowRightCircle size={25} /></button></a> */}
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5} className="justify-content-center" >
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "downloadSection animate__animated animate__zoomIn" : "downloadSection"}>
                   <h2 className="download">Join Today!</h2>
                   <a href="https://testflight.apple.com/join/3svxuIMP">
                    <button className="download apple"><span>Apple</span></button>
                  </a>
                  <a href="https://expo.dev/artifacts/eas/bXpVmWRerKY7WXAQawok3L.apk">
                    <button className="download android"><span>Android</span></button>
                  </a>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}