import { Banner } from "./Banner.js";
// import { Contact } from "./Contact.js";
import { Welcome } from "./Welcome.js";
import { WelcomeRight } from "./WelcomeRight.js";
import latestScreen from "../assets/img/Latest.png";
import journeyScreen from "../assets/img/Journey.png";
import sceneScreen from "../assets/img/Scenes.png";
import wellnessScreen from "../assets/img/WellnessAI.png";
import communityScreen from "../assets/img/Community.png";

export const Home = () => {
    return (
      <div className="Home">
        < Banner />
        < Welcome 
          header="Latest" 
          title="Find reccomended content in our Latest feed." 
          info="The Latest Feed allows you to share with people who follow you and to view suggested content." 
          image={latestScreen} 
        />
        < WelcomeRight 
          header="Journey" 
          title="Track your progress in your personal Journey." 
          info="All of your created content is automatically added to your Journey and any content that you Save is also added to it." 
          image={journeyScreen} 
        />
        < Welcome 
          header="Scenes" 
          title="Create collaborative Scenes for any event." 
          info="Scenes gives you the power to create shared memories with friends by easily inviting them and adding Shots to your Scene." 
          image={sceneScreen} 
        />
        < WelcomeRight 
          header="Wellness AI" 
          title="Our Wellness AI is here to assist you." 
          info="Ask for support or information on anything wellness related and let AI assist your Wellness Journey." 
          image={wellnessScreen} 
        />
        < Welcome 
          header="Community" 
          title="The Community is where you can find Clubs and Classes." 
          info="Find Clubs to join, and participate in. Clubs offer Live-Streamed Classes, and a place to connect with other members." 
          image={communityScreen} 
        />
        {/* < Contact /> */}
      </div>
    );
}