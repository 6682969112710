import { Container, Row, Col, Tab} from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';


export const WelcomeRight = (props) => {
  return (
    <section className="welcome welcomeRight snapScrolling" id="welcome">
      <Container className="welcomeContent">
      <Row className="aligh-items-center p-0">
          <Col xs={12} md={6} xl={7} className="justify-content-center" style={{display: 'flex'}}>
          <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                   <img className={"phoneScreen welcomeRight"} src={props.image} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "welcomeText welcomeRight animate__animated animate__fadeIn" : "welcomeText welcomeRight"}>
                <h1 className="f-let">{props.header}</h1>
                <h3>{props.title}</h3>
                <ul>
                  <li>
                  <p>{props.info}</p>
                  </li>
                </ul>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}