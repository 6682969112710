import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/logo.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { HashLink } from 'react-router-hash-link';
import TrackVisibility from 'react-on-screen';
import 'animate.css';


export const About = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Beautiful.", "Stunning.", "Magnificent.", "Gorgeous.", "Breathtaking.", "Serene.", "Captivating.", "Mesmerizing.", "Enchanting.", "Majestic.", "Radiant.", "Picturesque.", "Impressive.", "Striking.", "Exquisite.", "Inspiring.", "Peaceful.", "Tranquil.", "Harmonious.", "Delightful."];
  const period = 1000;
  
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="about snapScrolling" id="about">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>{`Hello!`}</h2>
                <p>{`Welcome to,`}</p>
                <h1>Clean Social</h1>
                {/* <h3><span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Tony", "Turntable" ]'><span className="wrap">{text}</span></span></h3> */}
                  <p>Clean is your personal wellness journey, supported by cutting-edge technology, bringing your goals to life. It's where every step is celebrated, every milestone shared. Clean is not just about being better; it's about feeling connected, supported, and inspired.</p>
                  <p>Built with Love in Canada, our Team is committed to delivering the next generation of Truly Social Media, focused on connecting individuals and their Communities with real world Scenes, Clubs, and Classes about your passion for life.</p>
                  <p>Never before has it been done, and we need YOU to ensure that we align with these goals. Download the App today! </p>
                  {/* <HashLink to='/#connect'>
                    <button>Connect <ArrowRightCircle size={25} /></button>
                  </HashLink> */}
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}