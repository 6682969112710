import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from './components/NavBar';
import { Footer } from './components/Footer';
import { Home } from "./components/Home.js";
import { About } from "./components/About.js";
import { Routes, Route} from 'react-router-dom';


function App() {
  

  return (
    <div className="App" id="scrollContainer">
      <NavBar/>
      <Routes>
        {/* Top-level routes */}
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;